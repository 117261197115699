const githubLink="https://github.com/milad014";
const instaLink="https://instagram.com/miladgeravand_";
const linkedInLink="https://www.linkedin.com/in/milad-geravand";
const twitterLink="https://twitter.com/";
const emailLink="mailto:miladgera014@gmail.com";

const profileLinks={
    githubLink,
    instaLink,
    linkedInLink,
    twitterLink,
    emailLink
}
const SocialData=profileLinks;
export default SocialData;

